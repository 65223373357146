.materialize-red {
  background-color: #e51c23 !important; }

.materialize-red-text {
  color: #e51c23 !important; }

.materialize-red.lighten-5 {
  background-color: #fdeaeb !important; }

.materialize-red-text.text-lighten-5 {
  color: #fdeaeb !important; }

.materialize-red.lighten-4 {
  background-color: #f8c1c3 !important; }

.materialize-red-text.text-lighten-4 {
  color: #f8c1c3 !important; }

.materialize-red.lighten-3 {
  background-color: #f3989b !important; }

.materialize-red-text.text-lighten-3 {
  color: #f3989b !important; }

.materialize-red.lighten-2 {
  background-color: #ee6e73 !important; }

.materialize-red-text.text-lighten-2 {
  color: #ee6e73 !important; }

.materialize-red.lighten-1 {
  background-color: #ea454b !important; }

.materialize-red-text.text-lighten-1 {
  color: #ea454b !important; }

.materialize-red.darken-1 {
  background-color: #d0181e !important; }

.materialize-red-text.text-darken-1 {
  color: #d0181e !important; }

.materialize-red.darken-2 {
  background-color: #b9151b !important; }

.materialize-red-text.text-darken-2 {
  color: #b9151b !important; }

.materialize-red.darken-3 {
  background-color: #a21318 !important; }

.materialize-red-text.text-darken-3 {
  color: #a21318 !important; }

.materialize-red.darken-4 {
  background-color: #8b1014 !important; }

.materialize-red-text.text-darken-4 {
  color: #8b1014 !important; }

.red {
  background-color: #F44336 !important; }

.red-text {
  color: #F44336 !important; }

.red.lighten-5 {
  background-color: #FFEBEE !important; }

.red-text.text-lighten-5 {
  color: #FFEBEE !important; }

.red.lighten-4 {
  background-color: #FFCDD2 !important; }

.red-text.text-lighten-4 {
  color: #FFCDD2 !important; }

.red.lighten-3 {
  background-color: #EF9A9A !important; }

.red-text.text-lighten-3 {
  color: #EF9A9A !important; }

.red.lighten-2 {
  background-color: #E57373 !important; }

.red-text.text-lighten-2 {
  color: #E57373 !important; }

.red.lighten-1 {
  background-color: #EF5350 !important; }

.red-text.text-lighten-1 {
  color: #EF5350 !important; }

.red.darken-1 {
  background-color: #E53935 !important; }

.red-text.text-darken-1 {
  color: #E53935 !important; }

.red.darken-2 {
  background-color: #D32F2F !important; }

.red-text.text-darken-2 {
  color: #D32F2F !important; }

.red.darken-3 {
  background-color: #C62828 !important; }

.red-text.text-darken-3 {
  color: #C62828 !important; }

.red.darken-4 {
  background-color: #B71C1C !important; }

.red-text.text-darken-4 {
  color: #B71C1C !important; }

.red.accent-1 {
  background-color: #FF8A80 !important; }

.red-text.text-accent-1 {
  color: #FF8A80 !important; }

.red.accent-2 {
  background-color: #FF5252 !important; }

.red-text.text-accent-2 {
  color: #FF5252 !important; }

.red.accent-3 {
  background-color: #FF1744 !important; }

.red-text.text-accent-3 {
  color: #FF1744 !important; }

.red.accent-4 {
  background-color: #D50000 !important; }

.red-text.text-accent-4 {
  color: #D50000 !important; }

.pink {
  background-color: #e91e63 !important; }

.pink-text {
  color: #e91e63 !important; }

.pink.lighten-5 {
  background-color: #fce4ec !important; }

.pink-text.text-lighten-5 {
  color: #fce4ec !important; }

.pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.pink-text.text-lighten-4 {
  color: #f8bbd0 !important; }

.pink.lighten-3 {
  background-color: #f48fb1 !important; }

.pink-text.text-lighten-3 {
  color: #f48fb1 !important; }

.pink.lighten-2 {
  background-color: #f06292 !important; }

.pink-text.text-lighten-2 {
  color: #f06292 !important; }

.pink.lighten-1 {
  background-color: #ec407a !important; }

.pink-text.text-lighten-1 {
  color: #ec407a !important; }

.pink.darken-1 {
  background-color: #d81b60 !important; }

.pink-text.text-darken-1 {
  color: #d81b60 !important; }

.pink.darken-2 {
  background-color: #c2185b !important; }

.pink-text.text-darken-2 {
  color: #c2185b !important; }

.pink.darken-3 {
  background-color: #ad1457 !important; }

.pink-text.text-darken-3 {
  color: #ad1457 !important; }

.pink.darken-4 {
  background-color: #880e4f !important; }

.pink-text.text-darken-4 {
  color: #880e4f !important; }

.pink.accent-1 {
  background-color: #ff80ab !important; }

.pink-text.text-accent-1 {
  color: #ff80ab !important; }

.pink.accent-2 {
  background-color: #ff4081 !important; }

.pink-text.text-accent-2 {
  color: #ff4081 !important; }

.pink.accent-3 {
  background-color: #f50057 !important; }

.pink-text.text-accent-3 {
  color: #f50057 !important; }

.pink.accent-4 {
  background-color: #c51162 !important; }

.pink-text.text-accent-4 {
  color: #c51162 !important; }

.purple {
  background-color: #9c27b0 !important; }

.purple-text {
  color: #9c27b0 !important; }

.purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.purple-text.text-lighten-5 {
  color: #f3e5f5 !important; }

.purple.lighten-4 {
  background-color: #e1bee7 !important; }

.purple-text.text-lighten-4 {
  color: #e1bee7 !important; }

.purple.lighten-3 {
  background-color: #ce93d8 !important; }

.purple-text.text-lighten-3 {
  color: #ce93d8 !important; }

.purple.lighten-2 {
  background-color: #ba68c8 !important; }

.purple-text.text-lighten-2 {
  color: #ba68c8 !important; }

.purple.lighten-1 {
  background-color: #ab47bc !important; }

.purple-text.text-lighten-1 {
  color: #ab47bc !important; }

.purple.darken-1 {
  background-color: #8e24aa !important; }

.purple-text.text-darken-1 {
  color: #8e24aa !important; }

.purple.darken-2 {
  background-color: #7b1fa2 !important; }

.purple-text.text-darken-2 {
  color: #7b1fa2 !important; }

.purple.darken-3 {
  background-color: #6a1b9a !important; }

.purple-text.text-darken-3 {
  color: #6a1b9a !important; }

.purple.darken-4 {
  background-color: #4a148c !important; }

.purple-text.text-darken-4 {
  color: #4a148c !important; }

.purple.accent-1 {
  background-color: #ea80fc !important; }

.purple-text.text-accent-1 {
  color: #ea80fc !important; }

.purple.accent-2 {
  background-color: #e040fb !important; }

.purple-text.text-accent-2 {
  color: #e040fb !important; }

.purple.accent-3 {
  background-color: #d500f9 !important; }

.purple-text.text-accent-3 {
  color: #d500f9 !important; }

.purple.accent-4 {
  background-color: #aa00ff !important; }

.purple-text.text-accent-4 {
  color: #aa00ff !important; }

.deep-purple {
  background-color: #673ab7 !important; }

.deep-purple-text {
  color: #673ab7 !important; }

.deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.deep-purple-text.text-lighten-5 {
  color: #ede7f6 !important; }

.deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.deep-purple-text.text-lighten-4 {
  color: #d1c4e9 !important; }

.deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.deep-purple-text.text-lighten-3 {
  color: #b39ddb !important; }

.deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.deep-purple-text.text-lighten-2 {
  color: #9575cd !important; }

.deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.deep-purple-text.text-lighten-1 {
  color: #7e57c2 !important; }

.deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.deep-purple-text.text-darken-1 {
  color: #5e35b1 !important; }

.deep-purple.darken-2 {
  background-color: #512da8 !important; }

.deep-purple-text.text-darken-2 {
  color: #512da8 !important; }

.deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.deep-purple-text.text-darken-3 {
  color: #4527a0 !important; }

.deep-purple.darken-4 {
  background-color: #311b92 !important; }

.deep-purple-text.text-darken-4 {
  color: #311b92 !important; }

.deep-purple.accent-1 {
  background-color: #b388ff !important; }

.deep-purple-text.text-accent-1 {
  color: #b388ff !important; }

.deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.deep-purple-text.text-accent-2 {
  color: #7c4dff !important; }

.deep-purple.accent-3 {
  background-color: #651fff !important; }

.deep-purple-text.text-accent-3 {
  color: #651fff !important; }

.deep-purple.accent-4 {
  background-color: #6200ea !important; }

.deep-purple-text.text-accent-4 {
  color: #6200ea !important; }

.indigo {
  background-color: #3f51b5 !important; }

.indigo-text {
  color: #3f51b5 !important; }

.indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.indigo-text.text-lighten-5 {
  color: #e8eaf6 !important; }

.indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.indigo-text.text-lighten-4 {
  color: #c5cae9 !important; }

.indigo.lighten-3 {
  background-color: #9fa8da !important; }

.indigo-text.text-lighten-3 {
  color: #9fa8da !important; }

.indigo.lighten-2 {
  background-color: #7986cb !important; }

.indigo-text.text-lighten-2 {
  color: #7986cb !important; }

.indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.indigo-text.text-lighten-1 {
  color: #5c6bc0 !important; }

.indigo.darken-1 {
  background-color: #3949ab !important; }

.indigo-text.text-darken-1 {
  color: #3949ab !important; }

.indigo.darken-2 {
  background-color: #303f9f !important; }

.indigo-text.text-darken-2 {
  color: #303f9f !important; }

.indigo.darken-3 {
  background-color: #283593 !important; }

.indigo-text.text-darken-3 {
  color: #283593 !important; }

.indigo.darken-4 {
  background-color: #1a237e !important; }

.indigo-text.text-darken-4 {
  color: #1a237e !important; }

.indigo.accent-1 {
  background-color: #8c9eff !important; }

.indigo-text.text-accent-1 {
  color: #8c9eff !important; }

.indigo.accent-2 {
  background-color: #536dfe !important; }

.indigo-text.text-accent-2 {
  color: #536dfe !important; }

.indigo.accent-3 {
  background-color: #3d5afe !important; }

.indigo-text.text-accent-3 {
  color: #3d5afe !important; }

.indigo.accent-4 {
  background-color: #304ffe !important; }

.indigo-text.text-accent-4 {
  color: #304ffe !important; }

.blue {
  background-color: #2196F3 !important; }

.blue-text {
  color: #2196F3 !important; }

.blue.lighten-5 {
  background-color: #E3F2FD !important; }

.blue-text.text-lighten-5 {
  color: #E3F2FD !important; }

.blue.lighten-4 {
  background-color: #BBDEFB !important; }

.blue-text.text-lighten-4 {
  color: #BBDEFB !important; }

.blue.lighten-3 {
  background-color: #90CAF9 !important; }

.blue-text.text-lighten-3 {
  color: #90CAF9 !important; }

.blue.lighten-2 {
  background-color: #64B5F6 !important; }

.blue-text.text-lighten-2 {
  color: #64B5F6 !important; }

.blue.lighten-1 {
  background-color: #42A5F5 !important; }

.blue-text.text-lighten-1 {
  color: #42A5F5 !important; }

.blue.darken-1 {
  background-color: #1E88E5 !important; }

.blue-text.text-darken-1 {
  color: #1E88E5 !important; }

.blue.darken-2 {
  background-color: #1976D2 !important; }

.blue-text.text-darken-2 {
  color: #1976D2 !important; }

.blue.darken-3 {
  background-color: #1565C0 !important; }

.blue-text.text-darken-3 {
  color: #1565C0 !important; }

.blue.darken-4 {
  background-color: #0D47A1 !important; }

.blue-text.text-darken-4 {
  color: #0D47A1 !important; }

.blue.accent-1 {
  background-color: #82B1FF !important; }

.blue-text.text-accent-1 {
  color: #82B1FF !important; }

.blue.accent-2 {
  background-color: #448AFF !important; }

.blue-text.text-accent-2 {
  color: #448AFF !important; }

.blue.accent-3 {
  background-color: #2979FF !important; }

.blue-text.text-accent-3 {
  color: #2979FF !important; }

.blue.accent-4 {
  background-color: #2962FF !important; }

.blue-text.text-accent-4 {
  color: #2962FF !important; }

.light-blue {
  background-color: #03a9f4 !important; }

.light-blue-text {
  color: #03a9f4 !important; }

.light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.light-blue-text.text-lighten-5 {
  color: #e1f5fe !important; }

.light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.light-blue-text.text-lighten-4 {
  color: #b3e5fc !important; }

.light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.light-blue-text.text-lighten-3 {
  color: #81d4fa !important; }

.light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.light-blue-text.text-lighten-2 {
  color: #4fc3f7 !important; }

.light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.light-blue-text.text-lighten-1 {
  color: #29b6f6 !important; }

.light-blue.darken-1 {
  background-color: #039be5 !important; }

.light-blue-text.text-darken-1 {
  color: #039be5 !important; }

.light-blue.darken-2 {
  background-color: #0288d1 !important; }

.light-blue-text.text-darken-2 {
  color: #0288d1 !important; }

.light-blue.darken-3 {
  background-color: #0277bd !important; }

.light-blue-text.text-darken-3 {
  color: #0277bd !important; }

.light-blue.darken-4 {
  background-color: #01579b !important; }

.light-blue-text.text-darken-4 {
  color: #01579b !important; }

.light-blue.accent-1 {
  background-color: #80d8ff !important; }

.light-blue-text.text-accent-1 {
  color: #80d8ff !important; }

.light-blue.accent-2 {
  background-color: #40c4ff !important; }

.light-blue-text.text-accent-2 {
  color: #40c4ff !important; }

.light-blue.accent-3 {
  background-color: #00b0ff !important; }

.light-blue-text.text-accent-3 {
  color: #00b0ff !important; }

.light-blue.accent-4 {
  background-color: #0091ea !important; }

.light-blue-text.text-accent-4 {
  color: #0091ea !important; }

.cyan {
  background-color: #00bcd4 !important; }

.cyan-text {
  color: #00bcd4 !important; }

.cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.cyan-text.text-lighten-5 {
  color: #e0f7fa !important; }

.cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.cyan-text.text-lighten-4 {
  color: #b2ebf2 !important; }

.cyan.lighten-3 {
  background-color: #80deea !important; }

.cyan-text.text-lighten-3 {
  color: #80deea !important; }

.cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.cyan-text.text-lighten-2 {
  color: #4dd0e1 !important; }

.cyan.lighten-1 {
  background-color: #26c6da !important; }

.cyan-text.text-lighten-1 {
  color: #26c6da !important; }

.cyan.darken-1 {
  background-color: #00acc1 !important; }

.cyan-text.text-darken-1 {
  color: #00acc1 !important; }

.cyan.darken-2 {
  background-color: #0097a7 !important; }

.cyan-text.text-darken-2 {
  color: #0097a7 !important; }

.cyan.darken-3 {
  background-color: #00838f !important; }

.cyan-text.text-darken-3 {
  color: #00838f !important; }

.cyan.darken-4 {
  background-color: #006064 !important; }

.cyan-text.text-darken-4 {
  color: #006064 !important; }

.cyan.accent-1 {
  background-color: #84ffff !important; }

.cyan-text.text-accent-1 {
  color: #84ffff !important; }

.cyan.accent-2 {
  background-color: #18ffff !important; }

.cyan-text.text-accent-2 {
  color: #18ffff !important; }

.cyan.accent-3 {
  background-color: #00e5ff !important; }

.cyan-text.text-accent-3 {
  color: #00e5ff !important; }

.cyan.accent-4 {
  background-color: #00b8d4 !important; }

.cyan-text.text-accent-4 {
  color: #00b8d4 !important; }

.teal {
  background-color: #009688 !important; }

.teal-text {
  color: #009688 !important; }

.teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.teal-text.text-lighten-5 {
  color: #e0f2f1 !important; }

.teal.lighten-4 {
  background-color: #b2dfdb !important; }

.teal-text.text-lighten-4 {
  color: #b2dfdb !important; }

.teal.lighten-3 {
  background-color: #80cbc4 !important; }

.teal-text.text-lighten-3 {
  color: #80cbc4 !important; }

.teal.lighten-2 {
  background-color: #4db6ac !important; }

.teal-text.text-lighten-2 {
  color: #4db6ac !important; }

.teal.lighten-1 {
  background-color: #26a69a !important; }

.teal-text.text-lighten-1 {
  color: #26a69a !important; }

.teal.darken-1 {
  background-color: #00897b !important; }

.teal-text.text-darken-1 {
  color: #00897b !important; }

.teal.darken-2 {
  background-color: #00796b !important; }

.teal-text.text-darken-2 {
  color: #00796b !important; }

.teal.darken-3 {
  background-color: #00695c !important; }

.teal-text.text-darken-3 {
  color: #00695c !important; }

.teal.darken-4 {
  background-color: #004d40 !important; }

.teal-text.text-darken-4 {
  color: #004d40 !important; }

.teal.accent-1 {
  background-color: #a7ffeb !important; }

.teal-text.text-accent-1 {
  color: #a7ffeb !important; }

.teal.accent-2 {
  background-color: #64ffda !important; }

.teal-text.text-accent-2 {
  color: #64ffda !important; }

.teal.accent-3 {
  background-color: #1de9b6 !important; }

.teal-text.text-accent-3 {
  color: #1de9b6 !important; }

.teal.accent-4 {
  background-color: #00bfa5 !important; }

.teal-text.text-accent-4 {
  color: #00bfa5 !important; }

.green {
  background-color: #4CAF50 !important; }

.green-text {
  color: #4CAF50 !important; }

.green.lighten-5 {
  background-color: #E8F5E9 !important; }

.green-text.text-lighten-5 {
  color: #E8F5E9 !important; }

.green.lighten-4 {
  background-color: #C8E6C9 !important; }

.green-text.text-lighten-4 {
  color: #C8E6C9 !important; }

.green.lighten-3 {
  background-color: #A5D6A7 !important; }

.green-text.text-lighten-3 {
  color: #A5D6A7 !important; }

.green.lighten-2 {
  background-color: #81C784 !important; }

.green-text.text-lighten-2 {
  color: #81C784 !important; }

.green.lighten-1 {
  background-color: #66BB6A !important; }

.green-text.text-lighten-1 {
  color: #66BB6A !important; }

.green.darken-1 {
  background-color: #43A047 !important; }

.green-text.text-darken-1 {
  color: #43A047 !important; }

.green.darken-2 {
  background-color: #388E3C !important; }

.green-text.text-darken-2 {
  color: #388E3C !important; }

.green.darken-3 {
  background-color: #2E7D32 !important; }

.green-text.text-darken-3 {
  color: #2E7D32 !important; }

.green.darken-4 {
  background-color: #1B5E20 !important; }

.green-text.text-darken-4 {
  color: #1B5E20 !important; }

.green.accent-1 {
  background-color: #B9F6CA !important; }

.green-text.text-accent-1 {
  color: #B9F6CA !important; }

.green.accent-2 {
  background-color: #69F0AE !important; }

.green-text.text-accent-2 {
  color: #69F0AE !important; }

.green.accent-3 {
  background-color: #00E676 !important; }

.green-text.text-accent-3 {
  color: #00E676 !important; }

.green.accent-4 {
  background-color: #00C853 !important; }

.green-text.text-accent-4 {
  color: #00C853 !important; }

.light-green {
  background-color: #8bc34a !important; }

.light-green-text {
  color: #8bc34a !important; }

.light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.light-green-text.text-lighten-5 {
  color: #f1f8e9 !important; }

.light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.light-green-text.text-lighten-4 {
  color: #dcedc8 !important; }

.light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.light-green-text.text-lighten-3 {
  color: #c5e1a5 !important; }

.light-green.lighten-2 {
  background-color: #aed581 !important; }

.light-green-text.text-lighten-2 {
  color: #aed581 !important; }

.light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.light-green-text.text-lighten-1 {
  color: #9ccc65 !important; }

.light-green.darken-1 {
  background-color: #7cb342 !important; }

.light-green-text.text-darken-1 {
  color: #7cb342 !important; }

.light-green.darken-2 {
  background-color: #689f38 !important; }

.light-green-text.text-darken-2 {
  color: #689f38 !important; }

.light-green.darken-3 {
  background-color: #558b2f !important; }

.light-green-text.text-darken-3 {
  color: #558b2f !important; }

.light-green.darken-4 {
  background-color: #33691e !important; }

.light-green-text.text-darken-4 {
  color: #33691e !important; }

.light-green.accent-1 {
  background-color: #ccff90 !important; }

.light-green-text.text-accent-1 {
  color: #ccff90 !important; }

.light-green.accent-2 {
  background-color: #b2ff59 !important; }

.light-green-text.text-accent-2 {
  color: #b2ff59 !important; }

.light-green.accent-3 {
  background-color: #76ff03 !important; }

.light-green-text.text-accent-3 {
  color: #76ff03 !important; }

.light-green.accent-4 {
  background-color: #64dd17 !important; }

.light-green-text.text-accent-4 {
  color: #64dd17 !important; }

.lime {
  background-color: #cddc39 !important; }

.lime-text {
  color: #cddc39 !important; }

.lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.lime-text.text-lighten-5 {
  color: #f9fbe7 !important; }

.lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.lime-text.text-lighten-4 {
  color: #f0f4c3 !important; }

.lime.lighten-3 {
  background-color: #e6ee9c !important; }

.lime-text.text-lighten-3 {
  color: #e6ee9c !important; }

.lime.lighten-2 {
  background-color: #dce775 !important; }

.lime-text.text-lighten-2 {
  color: #dce775 !important; }

.lime.lighten-1 {
  background-color: #d4e157 !important; }

.lime-text.text-lighten-1 {
  color: #d4e157 !important; }

.lime.darken-1 {
  background-color: #c0ca33 !important; }

.lime-text.text-darken-1 {
  color: #c0ca33 !important; }

.lime.darken-2 {
  background-color: #afb42b !important; }

.lime-text.text-darken-2 {
  color: #afb42b !important; }

.lime.darken-3 {
  background-color: #9e9d24 !important; }

.lime-text.text-darken-3 {
  color: #9e9d24 !important; }

.lime.darken-4 {
  background-color: #827717 !important; }

.lime-text.text-darken-4 {
  color: #827717 !important; }

.lime.accent-1 {
  background-color: #f4ff81 !important; }

.lime-text.text-accent-1 {
  color: #f4ff81 !important; }

.lime.accent-2 {
  background-color: #eeff41 !important; }

.lime-text.text-accent-2 {
  color: #eeff41 !important; }

.lime.accent-3 {
  background-color: #c6ff00 !important; }

.lime-text.text-accent-3 {
  color: #c6ff00 !important; }

.lime.accent-4 {
  background-color: #aeea00 !important; }

.lime-text.text-accent-4 {
  color: #aeea00 !important; }

.yellow {
  background-color: #ffeb3b !important; }

.yellow-text {
  color: #ffeb3b !important; }

.yellow.lighten-5 {
  background-color: #fffde7 !important; }

.yellow-text.text-lighten-5 {
  color: #fffde7 !important; }

.yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.yellow-text.text-lighten-4 {
  color: #fff9c4 !important; }

.yellow.lighten-3 {
  background-color: #fff59d !important; }

.yellow-text.text-lighten-3 {
  color: #fff59d !important; }

.yellow.lighten-2 {
  background-color: #fff176 !important; }

.yellow-text.text-lighten-2 {
  color: #fff176 !important; }

.yellow.lighten-1 {
  background-color: #ffee58 !important; }

.yellow-text.text-lighten-1 {
  color: #ffee58 !important; }

.yellow.darken-1 {
  background-color: #fdd835 !important; }

.yellow-text.text-darken-1 {
  color: #fdd835 !important; }

.yellow.darken-2 {
  background-color: #fbc02d !important; }

.yellow-text.text-darken-2 {
  color: #fbc02d !important; }

.yellow.darken-3 {
  background-color: #f9a825 !important; }

.yellow-text.text-darken-3 {
  color: #f9a825 !important; }

.yellow.darken-4 {
  background-color: #f57f17 !important; }

.yellow-text.text-darken-4 {
  color: #f57f17 !important; }

.yellow.accent-1 {
  background-color: #ffff8d !important; }

.yellow-text.text-accent-1 {
  color: #ffff8d !important; }

.yellow.accent-2 {
  background-color: #ffff00 !important; }

.yellow-text.text-accent-2 {
  color: #ffff00 !important; }

.yellow.accent-3 {
  background-color: #ffea00 !important; }

.yellow-text.text-accent-3 {
  color: #ffea00 !important; }

.yellow.accent-4 {
  background-color: #ffd600 !important; }

.yellow-text.text-accent-4 {
  color: #ffd600 !important; }

.amber {
  background-color: #ffc107 !important; }

.amber-text {
  color: #ffc107 !important; }

.amber.lighten-5 {
  background-color: #fff8e1 !important; }

.amber-text.text-lighten-5 {
  color: #fff8e1 !important; }

.amber.lighten-4 {
  background-color: #ffecb3 !important; }

.amber-text.text-lighten-4 {
  color: #ffecb3 !important; }

.amber.lighten-3 {
  background-color: #ffe082 !important; }

.amber-text.text-lighten-3 {
  color: #ffe082 !important; }

.amber.lighten-2 {
  background-color: #ffd54f !important; }

.amber-text.text-lighten-2 {
  color: #ffd54f !important; }

.amber.lighten-1 {
  background-color: #ffca28 !important; }

.amber-text.text-lighten-1 {
  color: #ffca28 !important; }

.amber.darken-1 {
  background-color: #ffb300 !important; }

.amber-text.text-darken-1 {
  color: #ffb300 !important; }

.amber.darken-2 {
  background-color: #ffa000 !important; }

.amber-text.text-darken-2 {
  color: #ffa000 !important; }

.amber.darken-3 {
  background-color: #ff8f00 !important; }

.amber-text.text-darken-3 {
  color: #ff8f00 !important; }

.amber.darken-4 {
  background-color: #ff6f00 !important; }

.amber-text.text-darken-4 {
  color: #ff6f00 !important; }

.amber.accent-1 {
  background-color: #ffe57f !important; }

.amber-text.text-accent-1 {
  color: #ffe57f !important; }

.amber.accent-2 {
  background-color: #ffd740 !important; }

.amber-text.text-accent-2 {
  color: #ffd740 !important; }

.amber.accent-3 {
  background-color: #ffc400 !important; }

.amber-text.text-accent-3 {
  color: #ffc400 !important; }

.amber.accent-4 {
  background-color: #ffab00 !important; }

.amber-text.text-accent-4 {
  color: #ffab00 !important; }

.orange {
  background-color: #ff9800 !important; }

.orange-text {
  color: #ff9800 !important; }

.orange.lighten-5 {
  background-color: #fff3e0 !important; }

.orange-text.text-lighten-5 {
  color: #fff3e0 !important; }

.orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.orange-text.text-lighten-4 {
  color: #ffe0b2 !important; }

.orange.lighten-3 {
  background-color: #ffcc80 !important; }

.orange-text.text-lighten-3 {
  color: #ffcc80 !important; }

.orange.lighten-2 {
  background-color: #ffb74d !important; }

.orange-text.text-lighten-2 {
  color: #ffb74d !important; }

.orange.lighten-1 {
  background-color: #ffa726 !important; }

.orange-text.text-lighten-1 {
  color: #ffa726 !important; }

.orange.darken-1 {
  background-color: #fb8c00 !important; }

.orange-text.text-darken-1 {
  color: #fb8c00 !important; }

.orange.darken-2 {
  background-color: #f57c00 !important; }

.orange-text.text-darken-2 {
  color: #f57c00 !important; }

.orange.darken-3 {
  background-color: #ef6c00 !important; }

.orange-text.text-darken-3 {
  color: #ef6c00 !important; }

.orange.darken-4 {
  background-color: #e65100 !important; }

.orange-text.text-darken-4 {
  color: #e65100 !important; }

.orange.accent-1 {
  background-color: #ffd180 !important; }

.orange-text.text-accent-1 {
  color: #ffd180 !important; }

.orange.accent-2 {
  background-color: #ffab40 !important; }

.orange-text.text-accent-2 {
  color: #ffab40 !important; }

.orange.accent-3 {
  background-color: #ff9100 !important; }

.orange-text.text-accent-3 {
  color: #ff9100 !important; }

.orange.accent-4 {
  background-color: #ff6d00 !important; }

.orange-text.text-accent-4 {
  color: #ff6d00 !important; }

.deep-orange {
  background-color: #ff5722 !important; }

.deep-orange-text {
  color: #ff5722 !important; }

.deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.deep-orange-text.text-lighten-5 {
  color: #fbe9e7 !important; }

.deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.deep-orange-text.text-lighten-4 {
  color: #ffccbc !important; }

.deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.deep-orange-text.text-lighten-3 {
  color: #ffab91 !important; }

.deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.deep-orange-text.text-lighten-2 {
  color: #ff8a65 !important; }

.deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.deep-orange-text.text-lighten-1 {
  color: #ff7043 !important; }

.deep-orange.darken-1 {
  background-color: #f4511e !important; }

.deep-orange-text.text-darken-1 {
  color: #f4511e !important; }

.deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.deep-orange-text.text-darken-2 {
  color: #e64a19 !important; }

.deep-orange.darken-3 {
  background-color: #d84315 !important; }

.deep-orange-text.text-darken-3 {
  color: #d84315 !important; }

.deep-orange.darken-4 {
  background-color: #bf360c !important; }

.deep-orange-text.text-darken-4 {
  color: #bf360c !important; }

.deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.deep-orange-text.text-accent-1 {
  color: #ff9e80 !important; }

.deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.deep-orange-text.text-accent-2 {
  color: #ff6e40 !important; }

.deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.deep-orange-text.text-accent-3 {
  color: #ff3d00 !important; }

.deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.deep-orange-text.text-accent-4 {
  color: #dd2c00 !important; }

.brown {
  background-color: #795548 !important; }

.brown-text {
  color: #795548 !important; }

.brown.lighten-5 {
  background-color: #efebe9 !important; }

.brown-text.text-lighten-5 {
  color: #efebe9 !important; }

.brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.brown-text.text-lighten-4 {
  color: #d7ccc8 !important; }

.brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.brown-text.text-lighten-3 {
  color: #bcaaa4 !important; }

.brown.lighten-2 {
  background-color: #a1887f !important; }

.brown-text.text-lighten-2 {
  color: #a1887f !important; }

.brown.lighten-1 {
  background-color: #8d6e63 !important; }

.brown-text.text-lighten-1 {
  color: #8d6e63 !important; }

.brown.darken-1 {
  background-color: #6d4c41 !important; }

.brown-text.text-darken-1 {
  color: #6d4c41 !important; }

.brown.darken-2 {
  background-color: #5d4037 !important; }

.brown-text.text-darken-2 {
  color: #5d4037 !important; }

.brown.darken-3 {
  background-color: #4e342e !important; }

.brown-text.text-darken-3 {
  color: #4e342e !important; }

.brown.darken-4 {
  background-color: #3e2723 !important; }

.brown-text.text-darken-4 {
  color: #3e2723 !important; }

.blue-grey {
  background-color: #607d8b !important; }

.blue-grey-text {
  color: #607d8b !important; }

.blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.blue-grey-text.text-lighten-5 {
  color: #eceff1 !important; }

.blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.blue-grey-text.text-lighten-4 {
  color: #cfd8dc !important; }

.blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.blue-grey-text.text-lighten-3 {
  color: #b0bec5 !important; }

.blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.blue-grey-text.text-lighten-2 {
  color: #90a4ae !important; }

.blue-grey.lighten-1 {
  background-color: #78909c !important; }

.blue-grey-text.text-lighten-1 {
  color: #78909c !important; }

.blue-grey.darken-1 {
  background-color: #546e7a !important; }

.blue-grey-text.text-darken-1 {
  color: #546e7a !important; }

.blue-grey.darken-2 {
  background-color: #455a64 !important; }

.blue-grey-text.text-darken-2 {
  color: #455a64 !important; }

.blue-grey.darken-3 {
  background-color: #37474f !important; }

.blue-grey-text.text-darken-3 {
  color: #37474f !important; }

.blue-grey.darken-4 {
  background-color: #263238 !important; }

.blue-grey-text.text-darken-4 {
  color: #263238 !important; }

.grey {
  background-color: #9e9e9e !important; }

.grey-text {
  color: #9e9e9e !important; }

.grey.lighten-5 {
  background-color: #fafafa !important; }

.grey-text.text-lighten-5 {
  color: #fafafa !important; }

.grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.grey-text.text-lighten-4 {
  color: #f5f5f5 !important; }

.grey.lighten-3 {
  background-color: #eeeeee !important; }

.grey-text.text-lighten-3 {
  color: #eeeeee !important; }

.grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.grey-text.text-lighten-2 {
  color: #e0e0e0 !important; }

.grey.lighten-1 {
  background-color: #bdbdbd !important; }

.grey-text.text-lighten-1 {
  color: #bdbdbd !important; }

.grey.darken-1 {
  background-color: #757575 !important; }

.grey-text.text-darken-1 {
  color: #757575 !important; }

.grey.darken-2 {
  background-color: #616161 !important; }

.grey-text.text-darken-2 {
  color: #616161 !important; }

.grey.darken-3 {
  background-color: #424242 !important; }

.grey-text.text-darken-3 {
  color: #424242 !important; }

.grey.darken-4 {
  background-color: #212121 !important; }

.grey-text.text-darken-4 {
  color: #212121 !important; }

.black {
  background-color: #000000 !important; }

.black-text {
  color: #000000 !important; }

.white {
  background-color: #FFFFFF !important; }

.white-text {
  color: #FFFFFF !important; }

.transparent {
  background-color: transparent !important; }

.transparent-text {
  color: transparent !important; }

/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.2.0
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover

 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */
/* 2D TRANSITIONS */
/* Grow */
/* Shrink */
/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

/* Pulse Grow */
@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); } }

/* Pulse Shrink */
@-webkit-keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@keyframes hvr-pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

/* Push */
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* Pop */
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); } }

@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); } }

/* Bounce In */
/* Bounce Out */
/* Rotate */
/* Grow Rotate */
/* Float */
/* Sink */
/* Bob */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); } }

@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); }
  50% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px); }
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); } }

@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); } }

@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px); } }

/* Hang */
@-webkit-keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); } }

@keyframes hvr-hang {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); } }

@-webkit-keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); } }

@keyframes hvr-hang-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); } }

/* Skew */
/* Skew Forward */
/* Skew Backward */
/* Wobble Vertical */
@-webkit-keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes hvr-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px); }
  33.3% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/* Wobble Horizontal */
@-webkit-keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes hvr-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px); }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

/* Wobble To Bottom Right */
@-webkit-keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px); }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px); }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px); }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px); }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes hvr-wobble-to-bottom-right {
  16.65% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px); }
  33.3% {
    -webkit-transform: translate(-6px, -6px);
    transform: translate(-6px, -6px); }
  49.95% {
    -webkit-transform: translate(4px, 4px);
    transform: translate(4px, 4px); }
  66.6% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px); }
  83.25% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

/* Wobble To Top Right */
@-webkit-keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px); }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px); }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px); }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px); }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes hvr-wobble-to-top-right {
  16.65% {
    -webkit-transform: translate(8px, -8px);
    transform: translate(8px, -8px); }
  33.3% {
    -webkit-transform: translate(-6px, 6px);
    transform: translate(-6px, 6px); }
  49.95% {
    -webkit-transform: translate(4px, -4px);
    transform: translate(4px, -4px); }
  66.6% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px); }
  83.25% {
    -webkit-transform: translate(1px, -1px);
    transform: translate(1px, -1px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

/* Wobble Top */
@-webkit-keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

@keyframes hvr-wobble-top {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

/* Wobble Bottom */
@-webkit-keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

@keyframes hvr-wobble-bottom {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

/* Wobble Skew */
@-webkit-keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

@keyframes hvr-wobble-skew {
  16.65% {
    -webkit-transform: skew(-12deg);
    transform: skew(-12deg); }
  33.3% {
    -webkit-transform: skew(10deg);
    transform: skew(10deg); }
  49.95% {
    -webkit-transform: skew(-6deg);
    transform: skew(-6deg); }
  66.6% {
    -webkit-transform: skew(4deg);
    transform: skew(4deg); }
  83.25% {
    -webkit-transform: skew(-2deg);
    transform: skew(-2deg); }
  100% {
    -webkit-transform: skew(0);
    transform: skew(0); } }

/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }

@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

/* Forward */
/* Backward */
/* BACKGROUND TRANSITIONS */
/* Fade */
/* Back Pulse */
@-webkit-keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75); } }

@keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75); } }

/* Sweep To Right */
/* Sweep To Left */
/* Sweep To Bottom */
/* Sweep To Top */
/* Bounce To Right */
/* Bounce To Left */
/* Bounce To Bottom */
/* Bounce To Top */
/* Radial Out */
/* Radial In */
/* Rectangle In */
/* Rectangle Out */
/* Shutter In Horizontal */
/* Shutter Out Horizontal */
/* Shutter In Vertical */
/* Shutter Out Vertical */
/* BORDER TRANSITIONS */
/* Border Fade */
/* Hollow */
/* Trim */
/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0; } }

@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0; } }

/* Ripple In */
@-webkit-keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1; } }

@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1; } }

/* Outline Out */
/* Outline In */
/* Round Corners */
/* Underline From Left */
/* Underline From Center */
/* Underline From Right */
/* Overline From Left */
/* Overline From Center */
/* Overline From Right */
/* Reveal */
/* Underline Reveal */
/* Overline Reveal */
/* SHADOW/GLOW TRANSITIONS */
/* Glow */
/* Shadow */
/* Grow Shadow */
/* Box Shadow Outset */
/* Box Shadow Inset */
/* Float Shadow */
/* Shadow Radial */
/* SPEECH BUBBLES */
/* Bubble Top */
/* Bubble Right */
/* Bubble Bottom */
/* Bubble Left */
/* Bubble Float Top */
/* Bubble Float Right */
/* Bubble Float Bottom */
/* Bubble Float Left */
/* ICONS */
/* Icon Back */
/* Icon Forward */
/* Icon Down */
@-webkit-keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%,
  75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

@keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%,
  75% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

/* Icon Down */
/* Icon Up */
@-webkit-keyframes hvr-icon-up {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%,
  75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

@keyframes hvr-icon-up {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  25%,
  75% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

/* Icon Up */
/* Icon Spin */
/* Icon Drop */
@-webkit-keyframes hvr-icon-drop {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  51%,
  100% {
    opacity: 1; } }

@keyframes hvr-icon-drop {
  0% {
    opacity: 0; }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  51%,
  100% {
    opacity: 1; } }

/* Icon Drop */
/* Icon Fade */
/* Icon Float Away */
@-webkit-keyframes hvr-icon-float-away {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em); } }

@keyframes hvr-icon-float-away {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-1em);
    transform: translateY(-1em); } }

/* Icon Float Away */
/* Icon Sink Away */
@-webkit-keyframes hvr-icon-sink-away {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em); } }

@keyframes hvr-icon-sink-away {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(1em);
    transform: translateY(1em); } }

/* Icon Sink Away */
/* Icon Grow */
/* Icon Shrink */
/* Icon Pulse */
@-webkit-keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes hvr-icon-pulse {
  25% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  75% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

/* Icon Pulse Grow */
@-webkit-keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

@keyframes hvr-icon-pulse-grow {
  to {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); } }

/* Icon Pulse Shrink */
@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

/* Icon Push */
@-webkit-keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); } }

@keyframes hvr-icon-push {
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); } }

/* Icon Pop */
@-webkit-keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }

@keyframes hvr-icon-pop {
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5); } }

/* Icon Bounce */
/* Icon Rotate */
/* Icon Grow Rotate */
/* Icon Float */
/* Icon Sink */
/* Icon Bob */
@-webkit-keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

@keyframes hvr-icon-bob {
  0% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); }
  50% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

@-webkit-keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

@keyframes hvr-icon-bob-float {
  100% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px); } }

/* Icon Hang */
@-webkit-keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px); }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

@keyframes hvr-icon-hang {
  0% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); }
  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px); }
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

@-webkit-keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

@keyframes hvr-icon-hang-sink {
  100% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); } }

/* Icon Wobble Horizontal */
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px); }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px); }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px); }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px); }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

/* Icon Wobble Vertical */
@-webkit-keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes hvr-icon-wobble-vertical {
  16.65% {
    -webkit-transform: translateY(6px);
    transform: translateY(6px); }
  33.3% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  49.95% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px); }
  66.6% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  83.25% {
    -webkit-transform: translateY(1px);
    transform: translateY(1px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/* Icon Buzz */
@-webkit-keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }

@keyframes hvr-icon-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); } }

/* Icon Buzz Out */
@-webkit-keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes hvr-icon-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

/* CURLS */
/* Curl Top Left */
/* Curl Top Right */
/* Curl Bottom Right */
/* Curl Bottom Left */
/*  -----  !!!!!!!!!!!  -----  */
/*  -----  MATERIALIZE  -----  */
/*  -----  !!!!!!!!!!!  -----  */
/*  -----  FOOTER  -----  */
/*  -----  GLOBAL  -----  */
/*  -----  GRID  -----  */
/*  -----  !!!!!  -----  */
/*  -----  THEME  -----  */
/*  -----  !!!!!  -----  */
/*  -----  GENERAL  -----  */
/*  -----  FORMS  -----  */
/*  -----  HEADER  -----  */
/*  -----  HOVERCSS  ----  */
p {
  text-align: left;
  margin-bottom: 1em; }
  @media only screen and (min-width: 991px) {
    p {
      font-size: 1.2em; } }

.page-layout-1column .column.main {
  margin-top: 2em;
  padding-left: 15px;
  padding-right: 15px; }

.page-header {
  max-width: 1280px;
  margin: 0 auto;
  display: block;
  width: 100%;
  position: relative; }
  .page-header .header.content {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    .page-header .header.content .action.nav-toggle {
      display: none; }
    .page-header .header.content .panel.wrapper .panel.header {
      padding-bottom: 0; }
    .page-header .header.content .minicart-wrapper, .page-header .header.content .block.block-search {
      margin-top: 0; }
    .page-header .header.content .block.block-search .field.search .control {
      padding-bottom: 0; }
  .page-header .logo {
    position: absolute;
    left: 0;
    top: -4rem;
    z-index: 10; }

.header-banner-wrap {
  display: block;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto; }
  .header-banner-wrap img {
    display: block;
    width: 100%; }

@media (max-width: 992px) {
  .page-header .logo {
    top: -1rem;
    left: 0;
    max-width: 250px; } }

@media (max-width: 810px) {
  .page-header .logo {
    max-width: 220px;
    top: -0.3rem; } }

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeue/HelveticaNeue.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeue/HelveticaNeueBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeue/HelveticaNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.checkout-cart-index .page-main .columns .column.main .cart-container {
  padding: 1rem; }
  .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions {
    font-size: 0; }
    @media (min-width: 768px) {
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions a, .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions button {
        font-size: 1.1rem;
        max-width: 51%;
        text-align: center;
        margin-left: 0;
        margin-bottom: 10px; } }
    @media (min-width: 1200px) {
      .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions {
        text-align: left; }
        .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions a, .checkout-cart-index .page-main .columns .column.main .cart-container .form-cart .actions button {
          font-size: 1.1rem;
          max-width: 31%;
          display: inline;
          margin-right: 1rem; } }

.category-request .form.customform .name {
  width: 50%;
  float: left;
  padding-right: 2rem; }

.category-request .form.customform .email {
  width: 50%;
  float: left; }

.category-request .form.customform .phone-number {
  clear: both; }

.catalog-category-view .breadcrumbs-wrapper .sortby-links {
  padding-right: 1em; }

.catalogsearch-result-index .breadcrumbs {
  text-align: left; }

.catalogsearch-result-index .columns .sidebar-wrapper {
  max-width: none;
  margin-left: 0;
  margin-right: 0; }

.catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item {
  margin-left: calc((100% - 3 * 33.333%) / 3);
  width: 33.333%;
  padding-left: 0; }
  .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info {
    width: 95%;
    margin-left: 5%; }
    .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details {
      text-align: left;
      position: relative; }
      .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name {
        max-width: 80%; }
        .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name .product-item-actions, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-name .product-item-actions {
          margin-top: 1rem !important; }
      .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-inner, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-inner {
        position: absolute;
        right: 0;
        top: 6%; }
        .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-inner .tocart, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .product-item-inner .tocart {
          border-radius: 24px;
          background: #ff5501;
          border: 1px solid #ff5501;
          padding: 12px 15px; }
      .catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .price-box, .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item .product-item-info .product-item-details .price-box {
        min-height: 30px; }

.catalogsearch-result-index .page-main .columns .column.main .products-grid .product-items .product-item:nth-child(3n + 1), .catalog-category-view .page-main .columns .column.main .products-grid .product-items .product-item:nth-child(3n + 1) {
  margin-left: 0; }

@media (min-width: 1300px) {
  .catalog-category-view .breadcrumbs-wrapper .sortby-links {
    padding-right: 0; } }

.stock.unavailable {
  display: none; }

#opc-new-shipping-address {
  margin-bottom: 2em; }
  #opc-new-shipping-address .fieldset > .field:not(.choice) > .label {
    font-weight: normal;
    display: block;
    width: 100%;
    float: none;
    text-align: left; }
  #opc-new-shipping-address .fieldset > .field.choice:last-child::before {
    display: none; }

.form-shipping-address,
.billing-address-form,
.form-login {
  margin-bottom: 2em; }
  .form-shipping-address .fieldset > .field:not(.choice) > .control,
  .billing-address-form .fieldset > .field:not(.choice) > .control,
  .form-login .fieldset > .field:not(.choice) > .control {
    float: none !important;
    width: 100% !important; }
  .form-shipping-address .fieldset > .field:not(.choice) > .label,
  .billing-address-form .fieldset > .field:not(.choice) > .label,
  .form-login .fieldset > .field:not(.choice) > .label {
    width: auto !important;
    height: auto !important;
    clip: auto !important; }
  .form-shipping-address .field-tooltip.toggle .label,
  .billing-address-form .field-tooltip.toggle .label,
  .form-login .field-tooltip.toggle .label {
    display: none; }

.billing-address-form .fieldset > .field.tig_postcode_nl {
  max-width: 100% !important; }

.opc-wrapper .fieldset > .field._required .label {
  width: auto;
  height: auto;
  clip: auto; }
  .opc-wrapper .fieldset > .field._required .label::after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px; }

fieldset .control .field._required .label {
  width: auto;
  height: auto;
  clip: auto; }
  fieldset .control .field._required .label::after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px; }

.cms-index-index .all-categories .category-wrapper,
.cms-home .all-categories .category-wrapper {
  display: inline-block;
  max-width: 33.33%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-align: left; }
  .cms-index-index .all-categories .category-wrapper:hover .category-link .category-name,
  .cms-home .all-categories .category-wrapper:hover .category-link .category-name {
    background-color: #150037;
    color: #fff; }
  .cms-index-index .all-categories .category-wrapper .category-link,
  .cms-home .all-categories .category-wrapper .category-link {
    font-size: 1rem;
    text-decoration: none; }
    .cms-index-index .all-categories .category-wrapper .category-link .category-name,
    .cms-home .all-categories .category-wrapper .category-link .category-name {
      border-bottom: none;
      font-size: 1.2em;
      color: #150037;
      font-weight: 700;
      padding: 1rem;
      margin-bottom: 0; }
    .cms-index-index .all-categories .category-wrapper .category-link img,
    .cms-home .all-categories .category-wrapper .category-link img {
      max-width: 350px; }

.contact-index-index #contact-form,
.contact-index-index .page-title-wrapper {
  max-width: 900px;
  margin: 0 auto; }

.contact-index-index #contact-form .fieldset .name,
.contact-index-index #contact-form .fieldset .email {
  width: 50%;
  float: left; }

.contact-index-index #contact-form .fieldset .name {
  padding-right: 1rem; }

.contact-index-index #contact-form .fieldset .email {
  padding-left: 1rem; }

.customer-account-index .page-title-wrapper {
  text-align: left; }

.customer-account-index .columns .sidebar-wrapper {
  max-width: none;
  width: auto;
  margin-left: 0;
  margin-right: 0; }

.customer-account-index .columns .column.main {
  max-width: none;
  margin-left: 0;
  margin-right: 0; }

.customer-account-login .page .messages, .customer-account-create .page .messages, .customer-account-forgotpassword .page .messages {
  width: 450px;
  margin: 0 auto; }

.customer-account-login .page-title-wrapper, .customer-account-create .page-title-wrapper, .customer-account-forgotpassword .page-title-wrapper {
  text-align: center;
  margin-bottom: 40px; }
  .customer-account-login .page-title-wrapper .page-title, .customer-account-create .page-title-wrapper .page-title, .customer-account-forgotpassword .page-title-wrapper .page-title {
    font-size: 1.1em;
    border: 0;
    margin-bottom: 0; }

.customer-account-login .column.main .login-container .fieldset::after {
  margin-left: 0; }

.customer-account-login .column.main .login-container .fieldset .actions-toolbar {
  margin-left: 0; }

.customer-account-login .column.main .login-container .block-customer-login {
  width: 600px;
  margin: 0 auto;
  float: none; }

.customer-account-create .column.main form, .customer-account-forgotpassword .column.main form {
  width: 600px;
  margin: 0 auto;
  text-align: center; }
  .customer-account-create .column.main form .fieldset .legend, .customer-account-forgotpassword .column.main form .fieldset .legend {
    margin: 0 auto;
    display: block;
    width: 100%; }
  .customer-account-create .column.main form .fieldset .field:not(.choice) > .control, .customer-account-forgotpassword .column.main form .fieldset .field:not(.choice) > .control {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .customer-account-create .column.main form .actions-toolbar, .customer-account-forgotpassword .column.main form .actions-toolbar {
    width: 74.2%;
    margin-left: auto;
    margin-right: auto; }

.customer-account-forgotpassword .fieldset .field.note {
  width: 74.2%;
  margin-left: auto;
  margin-right: auto;
  text-align: left; }

.customer-account-forgotpassword .fieldset .field:not(.choice) > .label {
  float: none;
  text-align: left;
  padding: 0;
  display: block;
  width: 74.2%;
  margin: 0 auto; }

.catalog-product-view .breadcrumbs .items {
  display: inline-block; }

.catalog-product-view .breadcrumbs .back-to-overview {
  display: inline-block;
  float: right;
  color: #37305d;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 300; }
  .catalog-product-view .breadcrumbs .back-to-overview:hover {
    cursor: pointer; }

@media (min-width: 1100px) {
  .catalog-product-view .columns .column.main .product-info-main .product-add-form .box-tocart .fieldset .field.qty {
    float: right;
    clear: none; } }

@media (min-width: 1100px) {
  .catalog-product-view .columns .column.main .product-info-main .product-add-form .box-tocart .fieldset .actions .action.primary.checkout, .catalog-product-view .columns .column.main .product-info-main .product-add-form .box-tocart .fieldset .actions .action.primary.tocart {
    display: inline-block;
    width: 49%;
    max-width: none; } }

.catalog-product-view .columns .column.main .product-info-main .product-add-form #product-options-wrapper .field.configurable.required {
  float: none; }
  @media (min-width: 1100px) {
    .catalog-product-view .columns .column.main .product-info-main .product-add-form #product-options-wrapper .field.configurable.required {
      position: absolute; } }
  .catalog-product-view .columns .column.main .product-info-main .product-add-form #product-options-wrapper .field.configurable.required .label, .catalog-product-view .columns .column.main .product-info-main .product-add-form #product-options-wrapper .field.configurable.required .control {
    display: inline-block; }

.catalog-product-view .columns .block.related {
  width: 57%; }
  .catalog-product-view .columns .block.related .block-title.title {
    text-align: left; }
  .catalog-product-view .columns .block.related .block-content.content .products-related .product-items .product-item {
    width: 32%;
    margin-left: 1.5%;
    margin-bottom: 0; }
    .catalog-product-view .columns .block.related .block-content.content .products-related .product-items .product-item:nth-child(3n) {
      margin-left: 1%; }
      .catalog-product-view .columns .block.related .block-content.content .products-related .product-items .product-item:nth-child(3n) .product-item-info {
        margin-left: 2.5%; }
    .catalog-product-view .columns .block.related .block-content.content .products-related .product-items .product-item .product-item-info {
      margin-left: 0.5%;
      width: 95%; }
