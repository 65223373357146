// =============================================
// Mixin - Breakpoint
// =============================================

$renderMode: 'mobile' !default;

@mixin bp($feature, $value) {
    // Set global device param
    $media: only screen;

    @media #{$media} and ($feature: $value) {
        @content;
    }
}

@mixin desktop() {
    @if $renderMode == 'desktop' {
        @content;
    }
}

@mixin mobile() {
    @if $renderMode == 'mobile' {
        @content;
    }
}